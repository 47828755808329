import React from 'react'

import PageHeader from '@c/page-header'
import ProjectImage from '@/components/project-image'

const What = ({
  // section 2
  rightTitleSmall,
  rightTitleBig,
  rightDescription,
  leftTitleSmall,
  leftTitleBig,
  leftDescription,

  // standalone image
  standaloneImage1,

  // section 3
  section3TitleSmall,
  section3TitleBig,
  section3DescriptionSmall,
  section3Description,
  section3Image
}) => (
  <section className="py-80 py-lg-120 py-xl-160 bg-white">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-5">
          <PageHeader
            topTitle={rightTitleSmall}
            title={rightTitleBig}
            type="h2"
          />
          <div
            className="mt-32"
            dangerouslySetInnerHTML={{ __html: rightDescription }}
          />
        </div>
        <div className="col-12 col-lg-5 offset-lg-2 mt-80 mt-lg-0">
          <PageHeader
            topTitle={leftTitleSmall}
            title={leftTitleBig}
            type="h2"
          />
          <div
            className="mt-32"
            dangerouslySetInnerHTML={{ __html: leftDescription }}
          />
        </div>
      </div>
      <div className="row mt-80 mt-lg-160 mt-xl-160">
        <div className="col-12">
          {standaloneImage1 && (
            <ProjectImage image={standaloneImage1} alt="what" />
          )}
        </div>
      </div>
      <div className="row align-items-center mt-80 mt-lg-160 mt-xl-160">
        <div className="col-12 col-lg-6">
          <PageHeader
            topTitle={section3TitleSmall}
            title={section3TitleBig}
            type="h2"
          />
          <h4 className="mt-32">{section3DescriptionSmall}</h4>
          <div
            className="mt-32"
            dangerouslySetInnerHTML={{ __html: section3Description }}
          />
        </div>
        <div className="col-12 col-lg-6 d-none d-lg-block ta-lg-right">
          {section3Image && <ProjectImage image={section3Image} alt="how" />}
        </div>
      </div>
    </div>
  </section>
)

export default What
