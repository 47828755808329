import React from 'react'

import PageHeader from '@c/page-header'
import ProjectImage from '@/components/project-image'

const Hero = ({ titleSmall, titleBig, description, image }) => (
  <section className="seo-hero-bg">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-lg-4">
          <PageHeader topTitle={titleSmall} title={titleBig} />
          <div
            className="mt-32"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div className="col-12 col-lg-6 offset-lg-2 mt-40 mt-lg-0 ta-center ta-lg-right">
          {image && <ProjectImage image={image} />}
        </div>
      </div>
    </div>
  </section>
)

export default Hero
