import React, { useMemo, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import cn from 'classnames'
import uniq from 'lodash/uniq'
import map from 'lodash/map'

import PageHeader from '@c/page-header'
import FeedbackAccordionItem from '@c/feedback-accordion-item'
import getContentByLocale from '@u/get-content-by-locale'

import './faq.css'

const query = graphql`
  query {
    translations: allDatoCmsTranslation {
      edges {
        node {
          locale
          optionAll
        }
      }
    }
  }
`

const ALL_CATEGORIES = 'ALL'

const Stories = ({ titleSmall, titleBig, faq: faqItems, locale }) => {
  const {
    translations: { edges: translationEdges }
  } = useStaticQuery(query)
  const translation = getContentByLocale(
    locale,
    translationEdges,
    'node.locale'
  )?.node
  const [faqCategories] = useState(() =>
    uniq(map(faqItems, 'category').filter(cat => !!cat)).sort()
  )
  const allCategoriesOption = {
    label: translation.optionAll,
    value: ALL_CATEGORIES
  }
  const filterOptions = [allCategoriesOption].concat(
    faqCategories.map(cat => ({
      label: cat,
      value: cat
    }))
  )
  const [selectedCategory, setSelectedCategory] = useState(allCategoriesOption)
  const filtered = useMemo(() => {
    return faqItems.filter(item => {
      if (selectedCategory.value === ALL_CATEGORIES) {
        return true
      }

      return item.category === selectedCategory.value
    })
  }, [selectedCategory])

  return (
    <section className="py-80 py-lg-40 bg-soft-blue">
      <div className="container">
        {/* header */}
        <div className="row">
          <div className="col-12">
            <PageHeader topTitle={titleSmall} title={titleBig} type="h2" />
          </div>
        </div>

        <div className="row mt-40 mt-lg-80">
          {/* Dato üzerinden sideDescription geliyor ama şuan içerisinde sadece 'test' yazısı geliyor. */}
          {/* <div className="col-12 col-lg-2" dangerouslySetInnerHTML={{ __html: sideDescription }} /> */}
          <div className="col-12 col-lg-2 d-flex flex-wrap flex-row flex-lg-column justify-content-between justify-content-lg-start mt-8">
            {filterOptions.map(option => {
              return (
                <button
                  key={option.value}
                  className={cn(
                    'content-jumplink mb-16 pb-8 border-b-soft-blue cursor-pointer faq-category-option',
                    option.value === selectedCategory.value &&
                      'content-jumplink-active'
                  )}
                  onClick={() => setSelectedCategory(option)}
                >
                  <p>{option.label}</p>
                </button>
              )
            })}
          </div>

          <div className="col-12 col-lg-9 offset-lg-1 mt-40 mt-lg-n24">
            {filtered.map(faq => (
              <FeedbackAccordionItem
                key={faq.id}
                title={faq.question}
                summary={faq.answer}
                locale={locale}
              />
            ))}
          </div>
        </div>

        {/* /.container */}
      </div>
    </section>
  )
}

export default Stories
