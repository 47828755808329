import React, { useRef, useEffect } from 'react'

import FlexibleLink from '@c/common/FlexibleLink'
import Slider from '@c/slider-flickity'
import Icon from '@c/icon'
import DatoCmsLink from '@m/datocms-components/DatoCmsLink'
import ProjectImage from '@/components/project-image'

const Testimonials = ({
  allReferencesLink,
  titleSmall,
  titleBig,
  titleSmallDescription,
  testimonials
}) => {
  const sliderStoryRef = useRef()
  const sliderArrowRef = useRef()
  const link = allReferencesLink && new DatoCmsLink(allReferencesLink)

  useEffect(() => {
    if (!sliderArrowRef.current) return

    const handler = () => {
      const selected = sliderArrowRef.current.selectedIndex

      sliderStoryRef.current.select(selected)
    }

    sliderArrowRef.current.on('select', handler)

    // unregister the handler
    return () => {
      sliderArrowRef.current.off('select', handler)
    }
  }, [sliderArrowRef])

  return (
    <section className="container d-flex flex-wrap my-lg-80">
      {/* titles */}
      <div className="col-12 col-lg-6">
        <h6 className="fs-h6 c-red">{titleSmall}</h6>
        <h6 className="fs-h2 mt-8">{titleBig}</h6>
        <p className="fs-h6 mt-24">{titleSmallDescription}</p>
      </div>

      <div className="col-12 col-lg-6 d-flex flex-row flex-wrap flex-lg-column justify-content-around align-items-center align-items-lg-end mt-32 mt-lg-0">
        {/* all-link */}
        <div className="d-none d-lg-inline mr-16">
          {link && (
            <FlexibleLink
              className="fs-small link-hover-red"
              title={
                <>
                  {link.getTitle()}
                  <Icon className="ml-8" />
                </>
              }
              url={link.getUrl()}
            />
          )}
        </div>
        {/* arrow */}
        <div
          className="col-12 col-lg-4 d-flex align-items-center justify-content-around"
          style={{ minWidth: 120 + 'px', maxWidth: 150 + 'px' }}
        >
          <Slider
            className="col-10"
            ref={sliderArrowRef}
            options={{
              prevNextButtons: true
            }}
          >
            {testimonials.map(testimonial => (
              <i key={testimonial.id}></i>
            ))}
          </Slider>
          {/* TODO: i/n şeklinde şu anki elemanın numarası görüntülenmeli. */}
          {/* <p className="col-2 mr-40 mr-lg-80">{currentTestimonial}/{testimonials.length}</p> */}
        </div>
      </div>

      <Slider
        className="col-12 mt-60 mt-lg-100"
        ref={sliderStoryRef}
        options={{
          pageDots: false,
          prevNextButtons: false,
          draggable: false
        }}
      >
        {testimonials.map(testimonial => (
          <div className="d-flex flex-wrap col-12" key={testimonial.id}>
            {/* images */}
            <div className="col-12 col-lg-5 pr-0 pr-lg-60">
              {testimonial.photo && (
                <ProjectImage className="story-img" image={testimonial.photo} />
              )}
              <div className="d-flex justify-content-start mb-60 mb-lg-120 mt-n100 mt-lg-n80">
                <div className="story-logo d-flex align-items-center justify-content-center">
                  {testimonial.companyLogo && (
                    <ProjectImage image={testimonial.companyLogo} />
                  )}
                </div>
              </div>
            </div>
            {/* description */}
            <div className="col-12 col-lg-7 px-0">
              <p className="border-l-pink pl-32 fs-large mb-100">
                {testimonial.quote1}
              </p>

              <h3 className="fs-h4">{testimonial.quote2}</h3>

              <p className="fs-large mt-32">{testimonial.quoteOwnerInfo}</p>
              {/*
                @fatihky
                Şimdilik bu butonu devre dışı bıraktım çünkü bu butonun gideceği kendisine özel bir sayfamız
                ya da sayfa bölümümüz bulunmuyor
              */}
              {/* <a className="btn bg-red c-white btn--round mt-40" href="#">
                Read More
              </a> */}
            </div>
          </div>
        ))}
      </Slider>
      {/* all-link */}
      <div className="d-inline d-lg-none ml-32 mt-32">
        {link && (
          <FlexibleLink
            className="fs-small link-hover-red"
            title={
              <>
                {link.getTitle()}
                <Icon className="ml-8" />
              </>
            }
            url={link.getUrl()}
          />
        )}
      </div>
    </section>
  )
}

export default Testimonials
