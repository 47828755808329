import React, { useRef, useEffect } from 'react'

import PageHeader from '@c/page-header'
import Slider from '@c/slider-flickity'
import RedLine from '@c/red-line'
import { Link } from 'gatsby'
import Icon from '@c/icon'
import FlexibleLink from '@c/common/FlexibleLink'
import DatoCmsLink from '@m/datocms-components/DatoCmsLink'
import setSliderOptions from '@u/set-slider-options'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import ProjectImage from '@/components/project-image'

const Zeo = ({
  titleSmall,
  titleBig,
  description,
  // section 1
  subSection1TitleSmall,
  subSection1TitleBig,
  subSection1Description,
  subSection1Image,
  subSection1Logo1,
  subSection1Logo2,
  // section 2
  subSection2Description,
  subSection2Image,
  subSection2Logo1,
  sliderOptions = setSliderOptions(),
  // attended conferences
  attendedConferencesTitleLine1,
  attendedConferencesTitleLine2,
  attendedConferencesTitleLine3,
  attendedConferenceLinks,
  // tools we use
  toolsTitleLine1,
  toolsTitleLine2,
  toolsDescription,
  toolLinks
}) => {
  const sliderToolRef = useRef()
  const sliderArrowRef = useRef()
  const conferenceLinks = attendedConferenceLinks.map(
    obj => new DatoCmsLink(obj)
  )
  const conferenceLinksWithImage = conferenceLinks.filter(link =>
    link.hasImage()
  )
  const toolLinksWithImage = toolLinks
    .map(obj => new DatoCmsLink(obj))
    .filter(link => link.hasImage())

  useEffect(() => {
    if (!sliderArrowRef.current) return

    function handler() {
      sliderToolRef.current.select(sliderArrowRef.current.selectedIndex)
    }

    sliderArrowRef.current.on('select', handler)

    return () => sliderArrowRef.current.off('select', handler)
  }, [sliderArrowRef, sliderToolRef])

  return (
    <section className="mt-80 mt-lg-120">
      <div className="container">
        {/* header */}
        <div className="row">
          <div className="col-12 col-lg-6">
            <PageHeader topTitle={titleSmall} title={titleBig} type="h2" />
          </div>
          <div
            className="col-12 col-lg-5 offset-lg-1 mt-32 mt-lg-40"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {/* section 1 */}
        <div className="row no-gutters mt-60 mt-lg-80 align-items-lg-center">
          <div className="col-12 col-lg-6 order-1 mt-40 mt-lg-0">
            <div className="border-box">
              <PageHeader
                topTitle={subSection1TitleSmall}
                title={subSection1TitleBig}
                type="h3"
                hideLine
              />
              <figure className="mt-40 ml-n32 mb-n32">
                {subSection1Image && <ProjectImage image={subSection1Image} />}
              </figure>
            </div>
          </div>
          <div className="col-12 col-lg-5 offset-lg-1 order-lg-2">
            <div>
              {subSection1Logo1 && (
                <ProjectImage
                  width={90}
                  objectFit={'contain'}
                  style={{ width: 90, objectFit: 'contain' }}
                  image={subSection1Logo1}
                />
              )}
            </div>
            <div className="mt-24">
              {subSection1Logo2 && (
                <ProjectImage width={211} image={subSection1Logo2} />
              )}
            </div>
            <div
              className="mt-32"
              dangerouslySetInnerHTML={{ __html: subSection1Description }}
            />
          </div>
        </div>
        {/* / section 1 */}

        {/* section 2 */}
        <div className="row no-gutters align-items-lg-center mt-60 mt-lg-0">
          <div className="col-12 col-lg-5">
            <div>
              {subSection2Logo1 && (
                <ProjectImage width={240} image={subSection2Logo1} />
              )}
            </div>
            <div
              className="mt-32"
              dangerouslySetInnerHTML={{ __html: subSection2Description }}
            />
          </div>
          <div className="col-12 col-lg-6 offset-lg-1 mt-40 mt-lg-0">
            <div className="border-box">
              <figure className="mx-n32 my-32">
                {subSection2Image && <ProjectImage image={subSection2Image} />}
              </figure>
            </div>
          </div>
        </div>
        {/* / section 2 */}
      </div>
      {/* The conferences we spoke */}
      <div className="mt-60 mt-lg-80 py-40 bg-light-pink">
        <div className="container d-flex flex-column flex-lg-row align-items-center">
          <div className="col-12 col-lg-4 ml-lg-120 py-120 mb-0 mb-lg-32">
            <p className="conf-heading fw-head-bold">
              {attendedConferencesTitleLine1}
            </p>
            <p className="conf-title">
              {attendedConferencesTitleLine2} <br />{' '}
              {attendedConferencesTitleLine3}{' '}
            </p>
          </div>
          <div className="conferences col-12 pb-140 pb-lg-0 px-0">
            <Slider options={sliderOptions}>
              {conferenceLinksWithImage.map(link => (
                <FlexibleLink
                  key={link.getId()}
                  className="col-12 col-lg-3 px-40 px-lg-16 mb-16 mb-lg-0"
                  title={
                    <GatsbyImage
                      className="conf-item"
                      height="40"
                      style={{
                        maxWidth: 320,
                        objectFit: 'contain',
                        margin: '0 auto'
                      }}
                      {...link.getImgProps()}
                    />
                  }
                  url={link.getUrl()}
                  target={'_blank'}
                  rel={'noopener noreferrer nofollow'}
                />
              ))}
              <i className="d-none d-lg-block col-9"></i>
            </Slider>
          </div>
        </div>
      </div>
      {/* / The conferences we spoke */}

      {/* awards */}
      {true == false && (
        <div className="awards py-100 py-lg-120">
          {/* title */}
          <div className="col-12 d-flex flex-column align-items-center">
            <div className="d-flex align-items-center">
              <img height="67" src="/assets/references/combined-shape.svg" />
              <p className="award-title mx-16">Aldığımız Ödüller</p>
              <img
                className="award-title-img"
                height="67"
                src="/assets/references/combined-shape.svg"
              />
            </div>
            <p className="mt-40 mb-60 c-text">
              We optimize your site in order to make your brand more visible
              within the search engine.{' '}
            </p>
          </div>
          {/* award items */}
          <div className="col-12 d-flex flex-column align-items-center justify-content-center mt-16">
            {/* award-1 */}
            <div className="col-12">
              <div className="col-12 col-lg-6 d-flex align-items-end justify-content-center pr-0 pr-lg-40">
                <div className="d-flex flex-column align-items-end ml-0 ml-lg-60">
                  <div className="d-flex">
                    <img
                      className="conf-item"
                      height="40"
                      src="/assets/references/european.png"
                    />
                    <div className="award-oval bg-smoke ml-16"></div>
                  </div>
                  <div className="d-flex mt-16 ml-0 ml-lg-32 mr-32">
                    <p className="award-number c-text">4</p>
                    <div className="award-rectangle d-flex align-items-center justify-content-center bg-red-dark mr-0 mr-lg-32 mt-16">
                      <p>
                        Four
                        <br />
                        Awards.
                      </p>
                    </div>
                    <p className="award-item-desc pl-8 pl-lg-0">
                      We optimize your site in order to make your brand more
                      visible within the search engine
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* award-2 */}
            <div className="col-12 mt-80 mt-lg-16">
              <div className="col-12 col-lg-6 d-flex justify-content-center ml-auto pl-0 pl-lg-32">
                <div className="d-flex flex-column align-items-start">
                  <div className="d-flex">
                    <div className="award-oval mr-16"></div>
                    <img
                      className="conf-item"
                      height="40"
                      src="/assets/references/mena.png"
                    />
                  </div>
                  <div className="d-flex mt-16 ml-32">
                    <p className="pr-16 pr-lg-0">
                      We optimize your site in order to make your brand more
                      visible within the search engine
                    </p>
                    <div className="award-rectangle d-flex align-items-center justify-content-center bg-dark mr-0 mr-lg-60 mt-16 ml-0 ml-lg-40">
                      <p className="award-number-2">3</p>
                      <p>
                        Three
                        <br />
                        Awards.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* articles */}
      {true == false && (
        <div className="bg-soft-blue pb-40">
          <div className="container">
            <div className="py-80 px-32">
              <div>
                <p className="fs-large">Headline</p>
                <h2 className="fs-h2 mb-32">Makaleler</h2>
                <RedLine />
              </div>
            </div>
          </div>
          <div className="container d-flex flex-wrap justify-content-between">
            <Link
              className="col-12 col-lg-6 d-flex flex-column flex-lg-row mb-120 mb-lg-0"
              to="#"
            >
              <img
                className="col-lg-5 col-12"
                alt="article-cover"
                height="190"
                src="/assets/resources/podcast-cover-2.jpg"
              />
              <div className="d-flex flex-column justify-content-between ml-16 ml-lg-0">
                <h4 className="fs-h4 mt-24 mt-lg-0">Makale List Title</h4>
                <p>
                  am feugiat turpis at risus blandit eleifend. Class aptent
                  taciti sociosqu ad litora torquent per conubia nostra.
                </p>
                <div className="d-flex align-items-center mt-24 mt-lg-0">
                  <img
                    className="speaker-img"
                    src="/assets/resources/podcast-cover-1.jpg"
                  />
                  <p className="ml-16">
                    Yiğit in <span className="h6">PPC</span>
                  </p>
                </div>
              </div>
            </Link>
            <Link
              className="col-12 col-lg-6 d-flex flex-column flex-lg-row mt-60 mt-lg-0"
              to="#"
            >
              <img
                className="col-lg-5 col-12"
                alt="article-cover"
                height="190"
                src="/assets/resources/podcast-cover-2.jpg"
              />
              <div className="d-flex flex-column justify-content-between ml-16 ml-lg-0">
                <h4 className="fs-h4 mt-24 mt-lg-0">Makale List Title</h4>
                <p>
                  am feugiat turpis at risus blandit eleifend. Class aptent
                  taciti sociosqu ad litora torquent per conubia nostra.
                </p>
                <div className="d-flex align-items-center mt-24 mt-lg-0">
                  <img
                    className="speaker-img"
                    src="/assets/resources/podcast-cover-1.jpg"
                  />
                  <p className="ml-16">
                    Yiğit in <span className="h6">PPC</span>
                  </p>
                </div>
              </div>
            </Link>
            <div className="ml-auto mr-32 mr-lg-16 mt-200 mt-lg-32">
              <a href="#">
                Book 2/ Book
                <Icon className="c-red ml-16" />
              </a>
            </div>
          </div>
        </div>
      )}
      {/* tools slider */}
      {true == false && (
        <div className="mt-160">
          <div className="container">
            {/* title */}
            <div className="row">
              <div className="col-12 col-lg-8">
                <PageHeader
                  topTitle={toolsTitleLine1}
                  title={toolsTitleLine2}
                  type="h2"
                />
                <p className="col-12 col-lg-6 pl-0 mt-16">{toolsDescription}</p>
              </div>
              {/* lg-all-link */}
              <div className="col-3 d-none d-lg-flex flex-row flex-lg-column align-items-center justify-content-between mt-12">
                {/* şu an bu linke ihtiyacımız yok. */}
                {/* <Link className="fs-small link-hover-red ml-auto" to="#">
                Tümünü Gör
                <Icon className="ml-8" />
              </Link> */}
                {/* arrow */}
                <div
                  className="col-3 d-flex align-items-center ml-32"
                  style={{ minWidth: 120 + 'px', maxWidth: 145 + 'px' }}
                >
                  <Slider
                    className="col-10"
                    ref={sliderArrowRef}
                    options={{
                      prevNextButtons: true
                    }}
                  >
                    {toolLinksWithImage.map(link => (
                      <i key={link.getId()}></i>
                    ))}
                  </Slider>
                  {/* TODO: i/n şeklinde şu anki elemanın numarası görüntülenmeli. */}
                  {/* <p className="ml-16 col-2">{currentTool}/{toolLinksWithImage.length}</p> */}
                </div>
              </div>
            </div>
          </div>
          {/* slider */}
          <div className="col-12 offset-0 offset-lg-1 pb-120 pb-lg-0 px-0 mt-60">
            <Slider
              className="tools-slider container-fluid px-0"
              options={sliderOptions}
              ref={sliderToolRef}
            >
              {toolLinksWithImage.map(link => (
                <FlexibleLink
                  key={link.getId()}
                  className="col-12 col-lg-3 px-32 py-40"
                  title={
                    <div className="tools-slider-item d-flex align-items-center justify-content-center px-32 py-60">
                      <GatsbyImage
                        className="ml-4"
                        alt={link.getImgProps().alt || ''}
                        image={getImage(link.getImgProps())}
                      />
                    </div>
                  }
                  url={link.getUrl()}
                />
              ))}
              {sliderOptions.desktopRes && (
                <i className="col-9 d-none d-lg-block"></i>
              )}
            </Slider>
          </div>
          {/* all-link */}
          <div className="d-block d-lg-none ml-32">
            <Link className="fs-small link-hover-red ml-auto" to="#">
              Tümünü Gör
              <Icon className="ml-8" />
            </Link>
          </div>
        </div>
      )}
    </section>
  )
}

export default Zeo
