import React from 'react'

import Slider from '@c/slider-flickity'
import PageHeader from '@c/page-header'
import ProjectImage from '@/components/project-image'

const SubServices = ({ title, description, items }) => (
  <section className="py-80 py-lg-120 py-xl-160 bg-light-pink">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-3">
          <PageHeader title={title} type="h2" />
        </div>
        <div
          className="col-12 col-lg-8 offset-lg-1 mt-32 mt-lg-0"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>

      <Slider className="SeoProcesses-slider mt-60 mt-lg-80" showOutside>
        {items.map((item, index) => (
          <div
            key={item.id}
            className="SeoProcesses-item slider-item border-box d-flex flex-column"
          >
            <h5>{item.title}</h5>

            <figure
              style={{ minHeight: 180 }}
              className="ta-center mt-32 ml-n32 mr-n32"
            >
              {item.image && <ProjectImage width="345" height="180" image={item.image} />}
            </figure>

            <div
              className="mt-32 flex-grow-1"
              dangerouslySetInnerHTML={{ __html: item.summary }}
            />

            {
              item.url && (
                <a href={item.url}>
                  <p className="btn btn--red btn--round mt-16">
                    {item.urlText}
                  </p>
                </a>
              )
            }

            <div className="mt-auto ta-right h5 fw-head-bold c-red-dark mt-16">
              {index < 10 ? `0${index + 1}` : index + 1}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  </section>
)

export default SubServices
