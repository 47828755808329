import React from 'react'

import PageHeader from '@c/page-header'
import ButtonPlay from '@c/button-play'
import { FlexibleLink } from '@c/common/FlexibleLink'
import Slider from '@c/slider-flickity'
import ProjectImage from '@/components/project-image'

const Stories = ({
  titleSmall,
  titleBig,
  description,
  items,
  referenceLogos
}) => {
  const [story1, story2, story3] = items

  return (
    <section className="">
      <div className="container">
        {/* header */}
        <div className="row">
          <div className="col-12 col-lg-8 col-lg-6">
            <PageHeader topTitle={titleSmall} title={titleBig} type="h2" />
            <div
              className="mt-32"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>

        {/* cards */}
        <div className="row align-items-center mt-60 mt-lg-100">
          <div className="col-12 col-lg-7">
            {/* CARD 1 */}
            {story1 && (
              <div className="row">
                <div className="col-12">
                  <FlexibleLink
                    url={story1.url}
                    WrapperElem={props => <div {...props} />}
                    className="story-box story-1 border-box border-box--hover d-flex flex-column"
                    title={
                      <>
                        <figure className="story-box-image">
                          {story1.image && (
                            <ProjectImage image={story1?.image} />
                          )}
                        </figure>
                        <h3>{story1.title}</h3>
                        <p>{story1.summary}</p>
                        <div className="mt-auto">
                          <ButtonPlay type="span" />
                        </div>
                      </>
                    }
                  />
                </div>
              </div>
            )}

            {/* CARD 2 */}
            {story2 && (
              <div className="row">
                <div className="col-12 col-xl-10 offset-xl-2 mt-40 mt-lg-100">
                  <FlexibleLink
                    url={story2.url}
                    WrapperElem={props => <div {...props} />}
                    className="story-box story-2 border-box border-box--hover d-flex flex-column bg-medium-pink border-0"
                    title={
                      <>
                        <figure className="story-box-image">
                          {story2.image && (
                            <ProjectImage image={story2.image} />
                          )}
                        </figure>
                        <h3>{story2.title}</h3>
                        <p>{story2.summary}</p>
                        <div className="mt-auto">
                          <ButtonPlay type="span" />
                        </div>
                      </>
                    }
                  />
                </div>
              </div>
            )}
          </div>

          {/* CARD 3 */}
          {story3 && (
            <div className="col-12 col-lg-4 offset-lg-1 mt-40 mt-lg-0">
              <FlexibleLink
                url={story3.url}
                WrapperElem={props => <div {...props} />}
                className="story-box story-3 border-box border-box--hover d-flex flex-column bg-soft-blue border-0"
                title={
                  <>
                    <figure className="story-box-image">
                      {story3.image && <ProjectImage image={story3?.image} />}
                    </figure>
                    <h3>{story3.title}</h3>
                    <p>{story3.summary}</p>
                    <div className="mt-auto">
                      <ButtonPlay type="span" />
                    </div>
                  </>
                }
              />
            </div>
          )}
        </div>
        <Slider
          className="mt-60 px-60"
          options={{
            pageDots: false,
            prevNextButtons: true,
            draggable: true
          }}
        >
          {(referenceLogos || []).map((item, index) => (
            <div key={index} className="col-12 col-lg-3 ta-center gray-scale back-from-gray-scale">
              <ProjectImage
                image={item}
                objectFit={'contain'}
                style={{ height: '170px', objectFit: 'contain' }}
              />
            </div>
          ))}
        </Slider>

        {/* /.container */}
      </div>
    </section>
  )
}

export default Stories
