import React from 'react'
import PropTypes from 'prop-types'
import Icon from './icon'

const IconPlay = () => (
  <Icon name="play" style={{ position: 'relative', left: 1 }} />
)

const Button = props => {
  return (
    <button type="button" {...props}>
      <IconPlay />
    </button>
  )
}
const Span = props => {
  return (
    <span {...props}>
      <IconPlay />
    </span>
  )
}

const ButtonPlay = ({ type, ...rest }) => {
  switch (type) {
    case 'span':
      return <Span {...rest} />
    default:
      return <Button {...rest} />
  }
}

ButtonPlay.defaultProps = {
  className: 'btn btn--large btn--round btn--icon bg-dark c-white'
}

ButtonPlay.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string
}

export default ButtonPlay
