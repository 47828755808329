import React from 'react'
import { graphql } from 'gatsby'
import DatoCMSModel from '../../models/DatoCMSModel'

import Layout from '@c/layout'

import Hero from '@v/seo/hero'
import SubServices from '@v/seo/sub-services'
import What from '@v/seo/what'
import Processes from '@v/seo/processes'
import Testimonials from '@v/seo/testimonials'
import Stories from '@v/seo/stories'
import Faq from '@v/seo/faq'
import Zeo from '@v/seo/zeo'

import './index.css'

const Page = ({ data, pageContext }) => {
  const { locale } = pageContext
  const { service } = data
  const model = new DatoCMSModel(service)

  console.log(service);

  return (
    <Layout locale={locale} seo={{ ...model.getSeoComponentProps() }}>
      <Hero
        titleSmall={service.heroTitleSmall}
        titleBig={service.heroTitleBig}
        description={service.heroDescription}
        image={service.heroImage}
        imageAsset={service.heroImageAsset}
      />

      <SubServices
        titleSmall={service.subServicesTitleSmall}
        titleBig={service.subServicesTitleBig}
        description={service.subServicesDescription}
        sideTitleFirst={service.subServicesSideTitleFirst}
        sideTitleSecond={service.subServicesSideTitleSecond}
        services={service.subServices}
      />

      <What
        rightTitleSmall={service.section2RightTitleSmall}
        rightTitleBig={service.section2RightTitleBig}
        rightDescription={service.section2RightDescription}
        leftTitleSmall={service.section2LeftTitleSmall}
        leftTitleBig={service.section2LeftTitleBig}
        leftDescription={service.section2LeftDescription}
        standaloneImage1={service.standaloneImage}
        section3TitleSmall={service.section3TitleSmall}
        section3TitleBig={service.section3TitleBig}
        section3DescriptionSmall={service.section3DescriptionSmall}
        section3Description={service.section3Description}
        section3Image={service.section3Image}
      />

      <Processes
        title={service.processesTitle}
        description={service.processesDescription}
        items={service.processesItems}
      />

      <Testimonials
        allReferencesLink={service.testimonialsDisplayAllReferencesLink[0]}
        titleSmall={service.testimonialsTitleSmall}
        titleBig={service.testimonialsTitleBig}
        titleSmallDescription={service.testimonialsTitleSmallDescription}
        testimonials={service.testimonials}
      />

      <Stories
        titleSmall={service.storiesTitleSmall}
        titleBig={service.storiesTitleBig}
        description={service.storiesDescription}
        items={service.storiesItems}
        referenceLogos={service.referenceLogos}
      />

      <Faq
        titleSmall={service.faqTitleSmall}
        titleBig={service.faqTitleBig}
        faq={service.faq}
        locale={locale}
      />

      <Zeo
        titleSmall={service.aboutZeoTitleSmall}
        titleBig={service.aboutZeoTitleBig}
        description={service.aboutZeoDescription}
        // section 1
        subSection1TitleSmall={service.aboutZeoSubSection1TitleSmall}
        subSection1TitleBig={service.aboutZeoSubSection1TitleBig}
        subSection1Description={service.aboutZeoSubSection1Description}
        subSection1Image={service.aboutZeoSubSectionOneImage}
        subSection1ImageAsset={service.aboutZeoSubSectionOneImageAsset}
        subSection1Logo1={service.aboutZeoSubSectionOneLogoOne}
        subSection1Logo1Asset={service.aboutZeoSubSectionOneLogo1Asset}
        subSection1Logo2={service.aboutZeoSubSectionOneLogoTwo}
        subSection1Logo2Asset={service.aboutZeoSubSectionOneLogoTwoAsset}
        // section 2
        subSection2Description={service.aboutZeoSubSection2Description}
        subSection2Image={service.aboutZeoSubSectionTwoImage}
        subSection2ImageAsset={service.aboutZeoSubSectionTwoImageAsset}
        subSection2Logo1={service.aboutZeoSubSectionTwoLogoOne}
        subSection2Logo1Asset={service.aboutZeoSubSectionTwoLogoOneAsset}
        // attended conferences
        attendedConferencesTitleLine1={service.attendedConferencesTitleLine1}
        attendedConferencesTitleLine2={service.attendedConferencesTitleLine2}
        attendedConferencesTitleLine3={service.attendedConferencesTitleLine3}
        attendedConferenceLinks={service.attendedConferenceLinks}
        // tools we use
        toolsTitleLine1={service.toolsTitleLine1}
        toolsTitleLine2={service.toolsTitleLine2}
        toolsDescription={service.toolsDescription}
        toolLinks={service.toolLinks}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ServicePageQuery($serviceId: String) {
    service: datoCmsService(id: { eq: $serviceId }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title

      # hero
      heroTitleSmall
      heroTitleBig
      heroDescription
      heroImage {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      # sub services
      subServicesTitleSmall
      subServicesTitleBig
      subServicesDescription
      subServicesSideTitleFirst
      subServicesSideTitleSecond
      subServices {
        id
        titleFirst
        titleSecond
        description
        icon {
          asset {
            localImage {
              extension
              publicURL
            }
          }
        }
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
            }
          }
        }

        url
      }

      # section 2 ("what")
      section2RightTitleSmall
      section2RightTitleBig
      section2RightDescription
      section2LeftTitleSmall
      section2LeftTitleBig
      section2LeftDescription

      # standalone image
      standaloneImage1: standaloneImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }

      # section 3
      section3TitleSmall
      section3TitleBig
      section3DescriptionSmall
      section3Description
      section3Image: sectionThreeImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }

      # processes section
      processesTitle
      processesDescription
      processesItems {
        id
        title
        summary
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
        url
        urlText
      }

      # testimonials
      testimonialsTitleSmall
      testimonialsTitleBig
      testimonialsTitleSmallDescription
      testimonials {
        id
        quote1
        quote2
        photo {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }

        companyLogo {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }

        quoteOwnerInfo
      }
      testimonialsDisplayAllReferencesLink {
        title
        url
        page {
          ...DatoCmsLinkFieldPage
        }
      }

      # stories
      storiesTitleSmall
      storiesTitleBig
      storiesDescription
      storiesItems {
        id
        title
        summary
        url
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }

      # reference logos
      referenceLogos {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }

      # faq
      faqTitleSmall
      faqTitleBig
      faq {
        id
        question
        answer
        category
      }

      # about zeo
      aboutZeoTitleSmall
      aboutZeoTitleBig
      aboutZeoDescription
      # section 1
      aboutZeoSubSection1TitleSmall
      aboutZeoSubSection1TitleBig
      aboutZeoSubSection1Description
      aboutZeoSubSectionOneImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }

      aboutZeoSubSectionOneLogoOne {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }

      aboutZeoSubSectionOneLogoTwo {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      # section 2
      aboutZeoSubSection2Description
      aboutZeoSubSectionTwoImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }

      aboutZeoSubSectionTwoLogoOne {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }

      # attended conferences
      attendedConferencesTitleLine1
      attendedConferencesTitleLine2
      attendedConferencesTitleLine3
      attendedConferenceLinks {
        id
        url
        page {
          ...DatoCmsLinkFieldPage
        }
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
      # tools we use
      toolsTitleLine1
      toolsTitleLine2
      toolsDescription
      toolLinks {
        id
        url
        page {
          ...DatoCmsLinkFieldPage
        }
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
