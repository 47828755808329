import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import find from 'lodash/find'

import Icon from '../icon'

import './index.css'

const query = graphql`
  query {
    translations: allDatoCmsTranslation {
      edges {
        node {
          locale
          didYouFindThisAnswerUseful
        }
      }
    }
  }
`

const FeedbackAccordionItem = ({ title, summary, locale }) => {
  const [isShow, setShow] = useState(false)
  const {
    translations: { edges: translationEdges }
  } = useStaticQuery(query)
  const translation = find(translationEdges, ['node.locale', locale])?.node

  const onToggle = () => {
    setShow(!isShow)
  }

  return (
    <div className="feedback-accoridon-item py-24">
      {/* header */}
      <header className="d-flex align-items-center">
        <h4>
          <button onClick={onToggle} type="button" className="ta-left">
            {title}
          </button>
        </h4>
        <span className="ml-auto d-flex align-items-center justify-content-center p-4">
          <Icon name={isShow ? 'top' : 'bottom'} />
        </span>
      </header>

      {isShow && (
        <div className="body">
          {/* summary */}
          <div
            className="mt-32 pre-line"
            dangerouslySetInnerHTML={{ __html: summary }}
          />

          {/* feedback */}
          <div className="mt-32">
            <p className="fs-small">
              {translation?.didYouFindThisAnswerUseful}
            </p>
            <div className="mt-8 ml-n8">
              <button
                type="button"
                className="btn btn--large btn--icon btn--round mr-8"
              >
                <Icon name="feedbackGood" />
              </button>
              <button
                type="button"
                className="btn btn--large btn--icon btn--round mr-8"
              >
                <Icon name="feedbackMeh" />
              </button>
              <button
                type="button"
                className="btn btn--large btn--icon btn--round mr-8"
              >
                <Icon name="feedbackBad" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FeedbackAccordionItem
