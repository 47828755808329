import React, { useEffect, useRef } from 'react'
import { Link } from 'gatsby'

import Slider from '@c/slider-flickity'
import PageHeader from '@c/page-header'
import ProjectImage from '@/components/project-image'
import setSliderOptions from '@/utils/set-slider-options'

const SubServices = ({
  titleSmall,
  titleBig,
  description,
  sideTitleFirst,
  sideTitleSecond,
  services
}) => {
  const sliderTextRef = useRef()
  const sliderArrowRef = useRef()
  const sliderOptions = setSliderOptions()

  useEffect(() => {
    if (!sliderArrowRef.current) return
    sliderArrowRef.current.on('select', () => {
      sliderTextRef.current.select(sliderArrowRef.current.selectedIndex)
    })
  }, [sliderArrowRef])


  return (
    <section className="py-80 py-lg-100 py-xl-140 seo-sub-services-bg">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-12 col-lg-6 col-xl-4">
            <PageHeader topTitle={titleSmall} title={titleBig} type="h2" />
            <div
              className="mt-32"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <div className="col-12 col-lg-6 offset-xl-2 ta-right d-none d-lg-block">
            <div className="h5">
              <span className="d-block fw-head-normal">{sideTitleFirst}</span>
              <span className="d-block fw-head-semibold">{sideTitleSecond}</span>
            </div>
          </div>
        </div>

        <Slider showOutside
          ref={sliderTextRef}
          options={sliderOptions}
          className="mt-60 ">
          {services.map(item => (
            <Link
              key={item.id}
              to={item.url}
              className="slider-item border-box border-box--hover"
            >
              <figure className="ta-center">
                <ProjectImage width="280" height="280" image={item.image} />
              </figure>

              <div className="d-flex align-items-center mt-32">
                <h4 className="flex-grow-1 fs-x-large">
                  <span className="d-block fw-head-normal">
                    {item.titleFirst}
                  </span>
                  <span className="d-block">{item.titleSecond}</span>
                </h4>
                <ProjectImage style={{ width: 40, height: 40 }} image={item.icon} />
              </div>

              <p
                className="fs-small mt-16"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </Link>
          ))}
        </Slider>

        {sliderOptions.desktopRes && (
          <div className="col-2 mt-60">
            <Slider
              className="slider-sub-arrow"
              options={{
                wrapAround: true,
                draggable: false,
                prevNextButtons: true
              }}
              ref={sliderArrowRef}
            >
              {services.map(i => (
                <i key={i}></i>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </section>
  )
}

export default SubServices
